/* eslint-disable */
import React, { ReactNode, FC } from 'react';
import { BreadcrumbsArrayConfiguration } from '../Breadcrumbs/Breadcrumbs';
import BreadcrumbsComponent from '../BreadcrumbsComponent';
import s from './PageWrapper.module.scss';

type PageWrapperProps = {
  children?: ReactNode | null;
  pageCrumbs: Array<BreadcrumbsArrayConfiguration>;
  pageTitle: string;
  contextElements?: ReactNode | null;
  isTestStatusPassed?: boolean;
  wrapperStyle?: React.CSSProperties;
  tooltipText?: string;
};

const PageWrapper: FC<PageWrapperProps> = ({
  children,
  pageTitle,
  pageCrumbs,
  contextElements,
  isTestStatusPassed,
  wrapperStyle,
  tooltipText,
}) => (
  <div className={`${s.wrapper}`}>
    <BreadcrumbsComponent
      isLID={pageTitle === 'LID Dashboard'}
      isTestPassed={isTestStatusPassed}
      titleName={pageTitle}
      crumbs={pageCrumbs}
      wrapperStyle={wrapperStyle}
      tooltipText={tooltipText}
    />
    {contextElements !== undefined && (
      <div className={` p-0 row ${s.wrapper__heading__margin}`}>
        <div className={`col-sm-12 p-0 ${s.wrapper__right}`}>
          {contextElements}
        </div>
      </div>
    )}
    <div className={`${s.wrapper__main}`}>{children}</div>
  </div>
);

export default PageWrapper;
