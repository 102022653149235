/* eslint-disable */
import { UseQueryOptions } from 'react-query';
import httpClient from '../../../helpers/http';

export const GetCDFIChartData = (vintage: '0' | '1'): UseQueryOptions => ({
  queryKey: [`CDFI-chart-data-${vintage}`],
  queryFn: () =>
    httpClient.get(`/api/cdfi/chart?vintage=${vintage}&dateRange=0`),
  refetchOnMount: 'always',
  refetchOnWindowFocus: 'always',
  notifyOnChangeProps: 'tracked',
  refetchOnReconnect: 'always',
});

export const GetCDFIStatsData = (vintage: '0' | '1'): UseQueryOptions => ({
  queryKey: [`CDFI-stats-data-${vintage}`],
  queryFn: () =>
    httpClient.get(`/api/cdfi/stats?vintage=${vintage}&dateRange=0`),
  refetchOnMount: 'always',
  refetchOnWindowFocus: 'always',
  notifyOnChangeProps: 'tracked',
  refetchOnReconnect: 'always',
});

export const GetCDFICertificationStatsData = (
  vintage: '0' | '1',
  fiscalYearEnd: Date,
  period: '0' | '1',
  showCustomIaData: boolean
): UseQueryOptions => ({
  queryKey: [
    `CDFI-loan-origination-stats-data-${vintage}`,
    vintage,
    fiscalYearEnd,
    period,
    showCustomIaData,
  ],
  queryFn: () =>
    httpClient.get(
      `/api/cdfi-loan-origination/stats?vintage=${vintage}&fiscalyearend=${fiscalYearEnd.toLocaleDateString(
        'en-US'
      )}&period=${period}&showCustomIaData=${showCustomIaData}`
    ),
  refetchOnMount: 'always',
  refetchOnWindowFocus: false,
  notifyOnChangeProps: 'tracked',
  refetchOnReconnect: false,
});

export const GetCDFICertificationChartData = (
  vintage: '0' | '1',
  fiscalYearEnd: Date,
  showCustomIaData: boolean
): UseQueryOptions => ({
  queryKey: [
    `CDFI-loan-origination-chart-data-${vintage}`,
    vintage,
    fiscalYearEnd,
    showCustomIaData,
  ],
  queryFn: () =>
    httpClient.get(
      `/api/cdfi-loan-origination/chart?vintage=${vintage}&showCustomIaData=${showCustomIaData}`
    ),
  refetchOnMount: 'always',
  refetchOnWindowFocus: false,
  notifyOnChangeProps: 'tracked',
  refetchOnReconnect: false,
});

export const SetEndOfFiscalYear = async (fiscalYearEnd: Date) => {
  const options = {
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  } as Intl.DateTimeFormatOptions;

  const response = await httpClient.post(
    '/api/cdfi-loan-origination/setfiscalyearend',
    {
      fiscalyearend: `${
        fiscalYearEnd.toLocaleString('en-US', options) + ' 00:00:00'
      }`,
    }
  );

  return response.data;
};
