import React, { FC } from 'react';
import s from './TestStatus.module.scss';
import success from './assets/success.svg';
import failed from './assets/failed.svg';

const TestStatus: FC<{ isPassed: boolean; isLid: boolean }> = ({
  isPassed,
  isLid,
}) => (
  <div
    className={`${s.statusElement}
    ${isPassed ? s.statusElement__is_true : s.statusElement__is_false}`}
  >
    <div className={`${s.statusElement__text}`}>
      <img src={isPassed ? success : failed} alt="status" />
      <span className={`${s.statusElement__text__status}`}>
        {isPassed
          ? 'Pre-qualified  '
          : `${isLid ? 'LID' : 'CDFI'} status not met`}
      </span>
    </div>
  </div>
);

export default TestStatus;
